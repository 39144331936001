#modal-container {
    z-index: 9999;
}

#modal-container > div {
    z-index: 10000;
}

#modal-container svg.fa-times-circle {
    color: #000;
}

#modal-container svg.fa-check {
    width: 18px;
}